<template>
  <v-card>
    <v-card-title>
      <span class="headline font-weight-light" v-if="quote.id">
        {{ $t('quotes.editQuote') }}
      </span>
      <span class="headline font-weight-light" v-else>
        {{ $t('quotes.createQuote') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-form ref="manageQuoteForm">
        <h3 class="pb-1">{{ $t('common.text') }}</h3>
        <v-textarea
          solo
          v-model="quote.text.en"
          :rules="[rules.required]"
          :label="$t('languages.en')"
          class="pl-3"
        ></v-textarea>
        <v-textarea
          solo
          v-model="quote.text.bg"
          :rules="[rules.required]"
          :label="$t('languages.bg')"
          class="pl-3"
        ></v-textarea>
        <h3 class="pb-1">{{ $t('common.author') }}</h3>
        <v-text-field
          solo
          v-model="quote.author.en"
          :label="$t('languages.en')"
          class="pl-3"
        ></v-text-field>
        <v-text-field
          solo
          v-model="quote.author.bg"
          :label="$t('languages.bg')"
          class="pl-3"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="save()">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import validators from '../../../core/validators';

export default {
  name: 'manage-quote-modal',
  props: {
    selectedQuote: Object
  },
  data() {
    return {
      quote: {
        text: {
          en: '',
          bg: ''
        },
        author: {
          en: '',
          bg: ''
        }
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField'))
      }
    };
  },
  created() {
    if (this.selectedQuote) {
      this.quote = this.selectedQuote;
    }
  },
  methods: {
    async save() {
      if (!this.$refs.manageQuoteForm.validate()) {
        return;
      }

      this.$emit('close', this.quote);
    }
  }
};
</script>
