var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"loading":_vm.loading,"loading-text":_vm.$t('common.loadingData'),"no-data-text":_vm.$t('common.noData')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-4",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('quotes.quotes')))]),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.createQuote()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('quotes-list-filter',{attrs:{"apply-filters":_vm.applyFilters,"applied-filters":_vm.filters}})]},proxy:true},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(item.text[_vm.currentUser.lang],50))+" ")]}},{key:"item.author",fn:function(ref){
var item = ref.item;
return [(item.author[_vm.currentUser.lang])?_c('span',[_vm._v(" "+_vm._s(item.author[_vm.currentUser.lang])+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":_vm.getApprovalColor(item.status)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"x-small":"","color":_vm.getApprovalColor(item.status)}},on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status))])])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.createdBy,20))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","fab":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.updateQuote(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(_vm.canDelete)?_c('v-btn',{staticClass:"mr-1",attrs:{"x-small":"","fab":"","depressed":"","color":"error"},on:{"click":function($event){return _vm.deleteQuote(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }