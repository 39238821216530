function transformQuoteToUpdateModel(quote) {
  return {
    id: quote.id,
    text: {
      en: quote.text.en,
      bg: quote.text.bg
    },
    author: {
      en: quote.author.en,
      bg: quote.author.bg
    }
  };
}

export default {
  transformQuoteToUpdateModel
};
