<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <quotes-list></quotes-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import QuotesList from '../components/QuotesList';

export default {
  name: 'quotes-page',
  components: {
    QuotesList
  }
};
</script>
