<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      :loading-text="$t('common.loadingData')"
      :no-data-text="$t('common.noData')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-4">
          <v-toolbar-title>{{ $t('quotes.quotes') }}</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn small fab depressed color="primary" @click="createQuote()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <quotes-list-filter
          :apply-filters="applyFilters"
          :applied-filters="filters"
        ></quotes-list-filter>
      </template>
      <template v-slot:item.text="{ item }">
        {{ item.text[currentUser.lang] | truncate(50) }}
      </template>
      <template v-slot:item.author="{ item }">
        <span v-if="item.author[currentUser.lang]">
          {{ item.author[currentUser.lang] }}
        </span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip top :color="getApprovalColor(item.status)">
          <template v-slot:activator="{ on }">
            <v-icon x-small :color="getApprovalColor(item.status)" v-on="on">
              mdi-circle
            </v-icon>
          </template>
          <span>{{ item.status }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.createdBy="{ item }">
        <span>
          {{ item.createdBy | truncate(20) }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          @click="updateQuote(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="canDelete"
          x-small
          fab
          depressed
          color="error"
          class="mr-1"
          @click="deleteQuote(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageQuoteModal from './ManageQuoteModal';
import QuotesListFilter from './QuotesListFilter';
import ConfirmModal from '../../shared/components/ConfirmModal';
import quotesMapper from '../mappers/quotes.mapper';
import approvalStatusesMixin from '../../shared/mixins/approval.statuses.mixin';
import listItemsMixin from '../../shared/mixins/list.items.mixin';
import {
  GET_QUOTES,
  ADD_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE
} from '../store/types';
import resources from '../../../enums/resources';
import aclMixin from '../../shared/mixins/acl.mixin';

export default {
  name: 'quotes-list',
  components: {
    QuotesListFilter
  },
  computed: {
    ...mapGetters({
      items: 'quotes',
      totalItems: 'totalQuotes',
      currentUser: 'currentUser'
    })
  },
  mixins: [aclMixin, listItemsMixin, approvalStatusesMixin],
  data() {
    return {
      loading: true,
      feature: resources.quotes,
      headers: [
        {
          text: this.$i18n.t('common.text'),
          value: 'text',
          sortable: false
        },
        {
          text: this.$i18n.t('common.author'),
          value: 'author',
          sortable: false
        },
        {
          text: this.$i18n.t('common.status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$i18n.t('common.createdBy'),
          value: 'createdBy',
          sortable: false
        },
        {
          text: this.$i18n.t('common.actions'),
          value: 'action',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async getItems(params) {
      this.loading = true;
      await this.$store.dispatch(GET_QUOTES, params);
      this.loading = false;
    },
    async createQuote() {
      const payload = await this.$root.$modal.show(ManageQuoteModal);

      if (!payload) return;

      await this.$store.dispatch(ADD_QUOTE, payload);
      this.resetList();

      this.$notify({
        type: 'success',
        text: this.$i18n.t('quotes.createSuccess')
      });
    },
    async updateQuote(quote) {
      const payload = await this.$root.$modal.show(ManageQuoteModal, {
        selectedQuote: quotesMapper.transformQuoteToUpdateModel(quote)
      });

      if (!payload) return;

      await this.$store.dispatch(UPDATE_QUOTE, payload);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('quotes.updateSuccess')
      });
    },
    async deleteQuote(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('quotes.deleteConfirmText')
      });

      if (!confirmed) return;

      await this.$store.dispatch(DELETE_QUOTE, id);

      this.resetList(false);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('quotes.deleteSuccess')
      });
    }
  }
};
</script>
